.lateral-menu {
  h2 {
    border-bottom: 1px solid $bg-section;
    padding-bottom: $base*3;
    margin-bottom: $base;
  }
  ul {
    background-color: #F8F9F9;
    list-style-type: none;
    margin: 0 $base;
    padding: 0;
    li {
      font-size: 14px;
      text-transform: none;
      display: flex;
      a {
        padding: $base $base*2;
        width: 100%;
        text-decoration: none;
        color: $dark-gold;
        &:hover {
          font-weight: 500;
        }
      }
      &.on {

        background-color: #F2EEEC;
      }
    }
  }
  @media #{$mobile}, #{$tablet} {
    display: none;
  }
}