@import '../../../../assets/scss/variables';

.App {
  main {
    &.landing {
      section {
        margin-top: 0;
        display: block;

        .inner {
          padding-top: $base*4;
          padding-bottom: $base*4;
          margin-left: auto;
          margin-right: auto;

          h2 {
            font-size: 20px;
          }

          .landing-cta-2 {
            margin-left: 0;
            padding-left: 0;
            justify-content: flex-start;
          }

          .landing-cta-4 {
            margin-top: 40px;
            width: fit-content;
          }

          .experiences-title {
            font-family: "Land Rover Web", sans-serif;
            font-size: 14px;
          }
        }

        &.hero {
          background-image: url("../../../../assets/img/landing/deco-hero-1280.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          height: 450px;
          width: 100%;
          margin: 112px auto 0;
          box-sizing: border-box;
          display: flex;
          align-items: center;


          h1 {
            color: #fff;
            display: block;
            text-align: center;
            margin-bottom: 0;
            small {
              display: block;
              font-family: 'Avenir Next', sans-serif;
              font-weight: 500;
              font-size: 18px;
            }
          }
        }

        &.intro {
        }

        &.gallery {
          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;

            li {
              margin: 0;
              padding: 0;
              flex: 0 0 calc(33% - 25px);
              a {
                color: $text;
                text-decoration: none;
                font-size: 10px;
                font-weight: 600;
                &:hover {
                  text-decoration: underline;
                }
              }

              img {
                max-width: 100%;
              }
              strong {
                display: block;
                margin: $base 0;
              }
            }
          }
          @media #{$tablet}, #{$mobile} {
                .tabs {
                  padding-left: 0;
                  padding-right: 0;
                  gap: 10px;
                  ul {
                    gap: 10px;
                  }
                }
                ul {
                  li {
                    flex: 0 0 100%;
                    img {
                      width: 100%;
                    }
                  }
                }

          }
          &.catalog {
            ul {
              li {
                strong:first-child {
                  height: 48px;
                  line-height: 16px;
                  margin-bottom: 24px;
                }
                strong:nth-child(2) {
                  color: #4A4F54;
                }
              }
            }
          }
        }

        &.benefits {
          display: flex;

          .inner {
            padding: 0 26px;
          }

          .img {
            img {
              width: 100%;
            }
          }

          .benefits-nav {
            line-height: 15px;
            border-bottom: 1px solid #9b9a98;
            font-size: 14px;

            .slick-track {
              display: flex;
              align-items: flex-end;
            }
            .slick-slide {
              padding: $base*2.5 0;
              text-transform: uppercase;
              margin-right: $base*3.5;
              border-bottom: 3px solid transparent;
              cursor: grab;
              white-space: nowrap;

              &:active {
                cursor: grabbing;
              }

              &:last-child {
                margin-right: 0;
              }

              @media #{$custom-slider-beneficts} {
                cursor: pointer;
                &:active {
                  cursor: pointer;
                }
              }
            }

            .slick-current {
              border-bottom: 3px solid $dark-gold;
            }
          }

          .benefits-content {
            padding: $base*2.5 0 0;
            display: flex;

            .content {
              img {
                display: block;
                margin-bottom: $base*3;
              }
              h5 {
                flex: 0 0 30%;
                margin-top: 0;
              }
              h4 {
                flex: 0 0 40%;
                max-width: 680px;
                letter-spacing: 1.2px;
              }

              > div {

                &:last-child {
                  /*display: flex !important;*/
                  align-items: flex-start;
                  gap: $base*5;
                }
              }
            }
          }


          @media #{$mobile} {
            .benefits-nav {
              .slick-slide {
                max-width: 184px;
                white-space: normal;
              }
            }
            .benefits-content {
              .content {
                > div {

                  &:last-child {
                    flex-direction: column;
                    gap: 0;
                  }
                }

                h4 {
                  flex: 1 1 auto;
                }
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
      .wadsworth + section.gallery {
        margin-top: $base*16
      }
    }
  }
}