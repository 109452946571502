@import '../../../assets/scss/variables';

.App {
  > header {
    background-color: $bg-section;
    height: $base*12;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 100;
    img {
      width: 150px;
      margin: 0 auto;
    }
    .MuiButtonBase-root {
      margin-right: 20px;
    }
    .logout {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
    .enviroment-tag {
      background: black;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
    }
  }
}