@import '../../../../assets/scss/variables';

.App {
  main {
    .password-reset {
      text-align: center;
      .inner {
        max-width: $base*70;
        ul {
          text-align: left;
          li {
            font-size: 14px;
          }
        }
        form,
        .actions {
          width: 100%;
          max-width: $base*45;
          margin: 0 auto;
        }
      }
    }
  }
}