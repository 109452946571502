@import '../variables';

.multiselect {
  .MuiSelect-multiple {
    padding-top: 14px;
    padding-bottom: 11px;
    padding-left: $base*2;
    border: 1px solid #5E5952;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 4px !important;
    overflow: hidden;
  }
}
.multiselect-options {
  height: 32px;
  .MuiSvgIcon-root {
    width: 18px !important;
    height: 18px !important;
  }
  .MuiTypography-root {
    font-size: 14px !important;
  }
}
.MuiSelect-select {
  &.Mui-disabled {
    background-color: #f1f1f1;
    opacity: 1;
    color: $dark-gold;
  }
}