@import '../variables';

select {
  -webkit-appearance: none;
  font-family: 'Avenir Next', sans-serif;
  background-image: url("../../../assets/img/drop-down.png");
  background-repeat: no-repeat;
  background-position: right 15px top 20px;
  line-height: 20px;
  &.normal {
    border-radius: $base*0.5;
    font-size: 14px;
    padding: $base*1.75 $base*1.25;
    border: 1px solid $dark-gold;
    box-sizing: border-box;
    width: 100%;
  }
  &.language-switcher {
    border-radius: $base*0.5;
    font-size: 14px;
    padding: $base*2 $base*4.5 $base*2 $base*2;
    border: 1px solid $dark-gold;

  }
  &:disabled {
    background-color: #f1f1f1;
    opacity: 1;
    color: $dark-gold;
  }
}
