.MuiModal-root {
  .MuiBox-root {
    border: 1px solid $dark-gold;
    max-width: 83%;
    max-height: 90%;
    overflow: auto;
    outline: none !important;
    border-radius: 5px;
  }
  .close {
    position: absolute;
    top: $base*2;
    right: $base*2;
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: $text-dark;
    cursor: pointer;
    padding: 0;
  }
  h2 {
    margin-bottom: $base*3;
  }
  .actions {
    display: flex;
    gap: 20px;
  }
  @media #{$mobile} {
    .actions {
      flex-direction: column-reverse;
    }
    .close {
      top: $base*2;
    }
  }
}