@import '../../../../assets/scss/variables';

.App {
  main {
    .password-recovery {
      text-align: center;
      .inner {
        max-width: $base*70;
        form,
        .actions {
          width: 100%;
          max-width: $base*45;
          margin: 0 auto;
          ul {
            padding-left: $base*2;
          }
          li,p  {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
}