@import '../../../../assets/scss/variables';

.App {
  .shared-notes {
    h2 + p {
      margin-bottom: $base*2
    }
    .counter {
      font-size: 10px;
    }
    .actions {
      margin-bottom: $base*9;
    }
    .comments {
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: $base*4;
      }
      .item {
        font-size: 14px;
        background-color: #F8F9F9;
        padding: $base*2.5;
        color: #5E5952;
        font-weight: normal;
        margin-bottom: $base*2;
        p {
          color: #383531;
          font-weight: 500;
          margin-top: $base;

        }
      }
    }
  }
}
