@import '../../../../assets/scss/variables';

.App {
  main {
    .address {
      margin-bottom: $base*5;
    }
    .address-header{
      display: flex;
      .btn-form {
        position: relative;
        display: flex;
      }
    }
    .resend {
      margin-bottom: $base*5
    }
  }
}