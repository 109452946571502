@import '../../../../assets/scss/variables';

.MuiModal-root {

  &.modal-contact {
    max-width: 600px;
    margin: 0 auto;
    .MuiBox-root {
      padding: 56px;
      width: 100%;
      max-width: 488px;
      border: 0;
    }

    .tabs {
      background-color: #E2D4CB;
      ul {
        display: flex;
        justify-content: center;

        li {
          border-bottom: 1px solid transparent;
          white-space: normal;
          span {
            font-size: 10px;
          }
          &.active {
            border-bottom-color: $text;
            span {
              color: $text-dark;
            }
          }
        }

      }
    }
  }
  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    height: 398px;
  }
  .card {
    padding: $base*4 $base*5 21px $base*5;
    text-align: left;
    height: 242px;
    max-width: 408px;
    &:last-child {
      margin-bottom: auto;
    }
    .name {
      font-size: 30px;
      display: block;
      margin: 22px 0 28px;
      font-weight: 500;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        font-size: 14px;
        margin-bottom: $base*3.25;
        display: flex;
        align-items: flex-start;

        span {
          font-size: 24px;
          margin-right: 16px;
          color: rgba(92, 92, 91, 1);

          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          text-decoration: none;
          color: $text;
        }
        p {
          margin: 0;
          height: 66px;
          overflow: hidden;
          line-height: 16px;
        }
        &:first-child {
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: auto;
      h4 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
  div.secondary {
    display: block;
    text-align: left;
    margin: 0 $base*5;
    padding-top: 27px;
    border-top: 1px solid rgba(94, 89, 82, 0.3);

    strong {
      font-size: 12px;
      margin-bottom: 16px;
      text-transform: uppercase;
      display: block;
      height: 28px;
    }

    div {
      display: flex;
      background-color: #F8F9F9;
      height: 44px;
      padding: 0 16px;
      align-items: center;
      font-weight: 500;

      .name {
        font-size: 14px;
      }

      .btn.text {
        font-size: 12px;
        text-decoration: underline;
        text-transform: none;
        letter-spacing: normal;
        color: #5E5952;
        font-weight: 300;
      }
    }
  }
  @media #{$mobile} {
    &.modal-contact {
      .MuiBox-root {
        padding: $base*6 $base*2 $base*3;
        box-sizing: border-box;
        max-width: calc(100% - #{$base}*2);
      }
    }
    .card {
      padding: $base*3 $base*2 21px $base*2;
      .name {
        margin: 18px 0 24px;
      }
    }
    div.secondary {
      margin: 0 $base*2;

      div {
        .btn.text {
          padding: 0;
        }
      }
    }
  }
}