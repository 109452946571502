@import '../../../../assets/scss/variables';


.contact-info {
  .actions {
    gap: 20px;
    display: flex;
    margin-bottom: $base*4;
    .btn {
      display: inline-flex;
      width: auto;
    }
  }
}