@import '../../../../assets/scss/variables';

.vehicles {
  .actions {
    margin-bottom: $base*2;
  }
  .item {
    margin-bottom: $base*3;
    border: 1px solid #CFCDCB;
    border-radius: 5px;
    header {
      padding: $base*2 $base*3;
      border-bottom: 1px solid #CFCDCB;
      background-color: $bg-section-light;
      display: flex;
      h2 {
        margin-bottom: 0;
        small {
          display: block;
          margin-top: 8px;
          font-size: 14px;
          strong {
            font-weight: 600;
          }
        }
      }
      [class^=icon-], [class*=" icon-"] {
        font-size: 18px;
      }
    }
    .actions {
      display: flex;
      [class^=icon-], [class*=" icon-"] {
        font-size: 16px;
      }
    }
    ul {
      list-style-type: none;
      margin: $base*3 $base*3 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      column-gap: 100px;
      row-gap: $base;
      li {
        flex: 1 1 calc(51% - 50px);
        display: flex;
        justify-content: space-between;
        column-gap: $base*1.5;
        span {
          white-space: nowrap;
          font-size: 14px;
          color: #5E5952;
        }
        strong {
          text-align: right;
        }
      }


    }
    .more-info {
      text-align: center;
      .btn {
        text-transform: none;
        font-size: 14px;
        letter-spacing: normal;
      }
    }

  }
  @media #{$mobile}, #{$tablet} {
    .item {
      ul  {
        flex-direction: column;
      }
    }
  }
}

.submenu {
  .MuiButtonBase-root {
    color: $text;
    font-family: 'Avenir Next', sans-serif;
    font-weight: normal;
    &.Mui-focusVisible {
      background-color: white;
      &:hover {
      background-color: rgba(0, 0, 0, 0.04)
      }
    }
  }
  a {
    color: $text;
    text-decoration: none;
    display: block;
    width: 100%;
  }
}