@import '../../../../assets/scss/variables';

.App {
  main {
    &.experience {

      .hero {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 40vw;
        width: 100%;
        margin: 0 auto;
        display: block;
        box-sizing: border-box;
      }

      .date {
        background-color: $bg-section-light;
        padding: $base*2;
        font: 18px "Land Rover Web", sans-serif;
        display: inline-block;
        margin-bottom: 30px;
        margin-right: auto;
        text-transform: uppercase;

      }
      .intro {
        h2 {
          letter-spacing: 3px;
          color: $text;
          line-height: 24px;
          margin-bottom: $base*2;
        }
        p {
          margin-bottom: $base*4;
        }
      }

      .title {
        padding: 50px 0 0;
        margin-top: 0;
        h1 {
          max-width: 100%;
          display: block;
          text-align: center;
          line-height: 38px;

          small {
            margin-top: $base*2.5;
            display: block;
            font-family: 'Avenir Next', sans-serif;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.34px;
            font-weight: 400;
          }
        }
        @media #{$tablet}, #{$mobile} {
          padding: 50px 25px 0;
        }
      }

      .info {
        text-align: left;
        @media #{$tablet}, #{$mobile} {
          margin-top: 0;
        }
      }

      .p1,
      .p2 {
        display: flex;
        gap: 30px;
        margin-bottom: 60px;
        @media #{$tablet}, #{$mobile} {
          margin-bottom: 15px;
        }

        img {
          width: 100%;
          max-width: 100%;
        }

        .img {
          flex: 0 1 calc(50% - 15px);
          @media #{$tablet}, #{$mobile} {
            flex: 0 1 auto;
          }
        }

        .text {
          flex: 0 0 calc(50% - 15px);
          p {
            margin-bottom: $base*3;
            @media #{$tablet}, #{$mobile} {
              flex: 0 0 auto;
            }

          }
        }
      }
      .p2 {
        flex-direction: row-reverse;
      }

      .slider {
        @media #{$tablet}, #{$mobile} {
          margin-top: 0;
        }
      }

      .footer-experience {
        background-color: $bg-section;
        padding: 60px 0 80px;
        .inner {
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
          max-width: 1160px;
          margin: 0 auto;
          h2 + p {
            margin-bottom: 24px;
          }
        }
        @media #{$tablet}, #{$mobile} {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
      @media #{$mobile} {
        .p1,
        .p2 {
          flex-direction: column;
        }
        .p2 {
          margin-bottom: 0;
        }
        .hero {
          height: 300px;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}