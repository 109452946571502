@import '../../../../assets/scss/variables';

.box-confirmation {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 760px;
  height: 403px;
  text-align: center;
  padding: 4px;
  background-color: white;
  border-radius: 5px;

    p.two-columns {
        display: flex;
        justify-content: space-around;
    }

  .box-content {
    padding: 60px;

    #modal-modal-title {
      color: $text;
    }

    #hac-name {
      font-weight: 500;
      margin-top: 35px;
    }

    #hac-email {
      line-height: 1px;
    }

    .modal-buttons {
      display: inline-flex;
      justify-content: space-between;
      padding: 40px;
      gap: 50px;

      .button-mod {
        width: 260px;
        padding: 16px;
        background: transparent;
        box-shadow: none;
      }

      .cancel-mod {
        color: $text;
        border: 1px solid rgba($color: $text-dark, $alpha: 0.2);
      }

      .confirm-mod {
        background-color: $dark-gold;
        color: white;
        border: 1px solid $dark-gold;
      }
    }
  }

}