h1 {
  font-family: 'Land Rover Web', sans-serif;
  font-size: 38px;
  line-height: 38px;
  margin: 0 0 $base*3.25;
  font-weight: 500;
  letter-spacing: 3px;
  color: $dark-gold;
}

h2 {
  text-transform: uppercase;
  font-family: 'Land Rover Web', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin: 0 0 $base*5 0;
  color: $dark-gold;
  & + p {
    &:last-child {
      margin-bottom: $base*10;
    }
  }
  @media #{$mobile} {
    & + p {
      margin-bottom: $base*5;
    }
  }
  &.no-caps {
    text-transform: none;
  }
  small {
    font-family: 'Avenir Next', sans-serif;
    display: block;
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
    margin-top: $base;
  }
}

h3 {
  font-family: 'Avenir Next', sans-serif;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: 0.34px;
  max-width: $base*85;
  font-weight: normal;
  border-bottom: 0;
  margin: 0 0 $base*3.5 0;
}

h4 {
  font-family: 'Land Rover Web', sans-serif;
  /*letter-spacing: 3px;*/
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 0 18px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $dark-gold;
}

h5 {
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.34px;
  max-width: $base*85;
  font-weight: 500;
  border-bottom: $base*2;
}
h6 {
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.34px;
  max-width: $base*85;
  border-bottom: 0;
  margin: 0;
  font-weight: 500;
}

p {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.34px;
  max-width: $base*125;
  margin: 0 0 $base*2.5 0;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: $text;
  }
  .note {
    font-size: 10px;
  }
}

