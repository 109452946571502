@import '../../../../assets/scss/variables';

.modal-unassign-retailerGM {
  h2 {
    text-align: left;
  }
  p {
    text-align: left;
  }
  .info {
    background-color: $bg-section-light;
    padding: $base*1.5 $base*2.5;
    margin: 0 0 $base*5;
    dl {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      font-size: 14px;
      div {
        flex: 1 1 30%;
        &:first-child {
          flex: 1 1 100%;
          margin-bottom: $base*1.5
        }
        &:nth-child(2) {
          flex: 1 1 70%;
          margin-bottom: $base*1.5
        }
        dt {
          margin: 0;
          padding: 0;
          color: $dark-gold;
          font-weight: normal;
          margin-bottom: $base;
        }
        dd {
          margin: 0;
          padding: 0;
          color: $text;
          font-weight: 500;
        }
      }
    }
  }
}

