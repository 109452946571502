@import '../../../../assets/scss/variables';

table.admin-pma {
  td,
  th {
    span.text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    &:nth-child(1),
    &:nth-child(2) {
      span.text {
        max-width: 136px;
        width: 136px;
      }
    }
    &:nth-child(3) {
      span.text {
        max-width: 350px;
        width: 350px;
      }
    }

    &:nth-child(4) {
      span.text {
        max-width: 160px;
        width: 160px;
      }
    }

    &:nth-child(5) {
      span.text {
        max-width: 244px;
        width: 244px;
      }
    }
  }
}