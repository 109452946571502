@import '../../../../assets/scss/variables';

.App {
  .tab-content {
    display: none;
    &.active,
    &.exited {
      display: flex;
    }
    .intro {
      display: flex;
      margin-bottom: $base*5;
      .actions {
        margin-left: auto;
        display: flex;
      }
    }
    .no-result {
      text-align: center;
      display: block;
      margin: 0 auto;
      max-width: 100%;
      h2 {
        small {
          margin-top: $base*2;
        }
      }
      .btn {
        padding: 0 $base*2;
        display: inline-flex;
        margin: 0 auto;
      }
    }
  }
  @media #{$mobile} {
    .intro {
      flex-direction: column;
      .actions {
        margin-left: 0 !important;
        margin-top: $base;
        .btn {
          padding: 0;
        }
      }
    }
  }
}