html {
  body {
    font-family: 'Aventir Next', sans-serif;
    color: $text;
    margin: 0;
    overflow: auto !important;
    padding-right: 0 !important;

    .App {
      header {
        .language-switcher {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      > main,
      > footer {
        padding-top: $base*12;
        white-space: pre-line;
        .dark {
          background-color: $bg-section;
        }
        .filters {
          margin-bottom: $base*5;
        }
        > section {
          display: flex;
          justify-content: center;
          margin-top: 60px;
          .inner {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
            max-width: 1160px;
            margin: 0 $base*7.5 $base*3.5;
            width: calc(100% - #{$base}*15);
            .table-wrapper {
              max-width: 100%;
              overflow-y: auto;
            }
            &.menu {
              flex-direction: row;
              justify-content: space-between;
              aside {
                width: $base*32.5;
              }
              aside + main {
                width: $base*95;
              }
            }
          }
          &.legal,
          &.form {
            .inner {
              max-width: $base*95;
              .actions {
                width: 100%;
                max-width: $base*45;
                margin: 0 auto;
              }
            }
          }
          &.settings,
          &.vehicles,
          &.unenroll,
          &.shared-notes{
            .inner {
              max-width: $base*95;
            }
          }
        }
        .wadsworth + section {
          margin-top: $base*20;
        }
      }
      > footer {
        padding-top: 0;
      }
      @media #{$mobile} {
        > main {
          > section {
            .inner {
              margin: $base*5.25 $base*3.25;
              width: calc(100% - #{$base}*6.5)
            }
            &.form {
              margin: $base*5.25 $base*3.25;
              form {
                max-width: 760px;
              }
            }
          }
        }
      }
    }
  }
}
