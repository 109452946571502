
.datepicker {
  &.form-control {
    display: block;
    label {
      display: block;
      padding-top: 3px;
    }
    .MuiFormControl-root {
      background-color: white;
      width: 100%;
      .MuiInputBase-root {
        .MuiInputBase-input {
          border: 0;
          height: 50px !important;
          font-family: 'Avenir Next', sans-serif;
          &[readonly] {
            background-color: transparent;
          }
        }
        .MuiButtonBase-root {
          background-color: #5E5952 !important;
          border-radius: 0;
          padding: 14px;
          color: white;
          margin-right: -13px;
        }
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $dark-gold;
    &.Mui-focused {
      border-width: 1px;
    }
  }
}
