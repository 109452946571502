@import '../variables';

input[type='text'],
input[type='email'],
input[type='password'] {
  font-family: 'Avenir Next', sans-serif;
  border-radius: $base*0.5;
  font-size: 14px;
  padding: $base*1.75 $base*1.25;
  border: 1px solid $dark-gold;
  box-sizing: border-box;
  width: 100%;
  line-height: 20px;
  font-weight: 500;
  &.error {
    border-color: red;
  }
  &:read-only {
    background-color: #f1f1f1;
  }
}
