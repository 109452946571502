.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}

.hidden {
  display: none !important;
}

.disabled {
  opacity: 0.2;
}

.MuiMenu-root {
  .MuiMenu-paper {
    li {
      span {
        font-size: 16px;
        margin-right: $base*2;
      }
    }
  }
}