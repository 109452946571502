@import '../../../../assets/scss/variables';

.App {
  main {
    .edit-preferences{
      small {
        font-size: 12px !important;
      }
      strong {
        font-size: 14px;
      }
    }
  }
}