@import '../../../../assets/scss/variables';

.on-behalf{
  .form-control {
    margin-bottom: 0 !important;
    &.error {
      color: red;
    }
  }
  p.error {
    color: red;
  }
  .actions {
    margin-top: $base*5;
  }

}

