@import '../../../../assets/scss/variables';

table.retailer-active {
  td,
  th {
    span.text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    &:nth-child(1),
    &:nth-child(2) {
      span.text {
        max-width: 136px;
        width: 136px;
      }
    }
    &:nth-child(3) {
      span.text {
        max-width: 178px;
        width: 178px;
      }
    }
    &:nth-child(4) {
      span.text {
        max-width: 274px;
        width: 274px;
      }
    }

    &:nth-child(6) {
      span.text {
        max-width: 112px;
        width: 112px;
      }
    }

  }
  &.retailerGM {
    td,
    th {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        span.text {
          max-width: 136px;
          width: 136px;
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        span.text {
          max-width: 190px;
          width: 190px;
        }
      }

      &:nth-child(6) {
        span.text {
          max-width: 94px;
          width: 94px;
        }
      }

      &:nth-child(7) {
        span.text {
          max-width: 112px;
          width: 112px;
        }
      }
    }
  }
}