.form-control {
  margin-bottom: $base*2;
  text-align: left;
  label {
    margin-bottom: $base;
    display: inline-flex;
    font-size: 12px;
    font-weight: normal;
  }
  &.required {
    label {
      &:after {
        content: '*';
        margin-left: 5px;
        color: $error;
      }
    }
  }
  &.error {
    input,
    select {
      border: 1px solid red;
    }
    span {
      font-size: 11px;
      color: red;
    }
  }
}