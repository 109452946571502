@import '../../../../assets/scss/variables';

.App {
  .edit-vehicle {
    .actions {
      display: flex;
      gap: $base*3;
      margin-top: $base*5;
    }
    .error {
      color: $error;
      font-size: small;
    }
    @media #{$mobile} {
      .actions {
        flex-direction: column-reverse;
      }
    }
  }
}