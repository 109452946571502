@import '../../../../assets/scss/variables';

.reasign-saleperson {
  .actual {
    border: 1px solid rgba(56, 53, 49, 0.2);
    padding: $base*1.5 $base*2.5;
    text-align: left;
    border-radius: $base*0.5;
    margin-bottom: $base*5;
    p {
      margin-bottom: $base;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .info {
    background-color: $bg-section-light;
    padding: $base*1.5 $base*2.5;
    margin: 0 0 $base*2;
    dl {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      font-size: 14px;
      div {
        flex: 1 1 50%;
        margin-bottom: $base*1.5;
        &:first-child {
          flex: 1 1 100%;
        }
        &:last-child {
          margin-bottom: 0;
        }
        dt {
          margin: 0;
          padding: 0;
          color: $dark-gold;
          font-weight: normal;
          margin-bottom: $base;
        }
        dd {
          margin: 0;
          padding: 0;
          color: $text;
          font-weight: 500;
        }
      }
    }
  }
}

