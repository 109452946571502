@import '../../../../assets/scss/variables';

.preferences {
  .actions {
    margin-bottom: $base*2;
    display: flex;
    margin-right: auto;
    .btn.text {
      font-size: 10px;
      font-weight: 600;
      color: #5E5952;
      letter-spacing: 3px;
    }
    [class^=icon-], [class*=" icon-"] {
      font-size: 16px;
    }
  }
}
.hac-preferences {
  list-style: none;
  background-color: red;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    background-color: $bg-section;
    &:nth-child(even) {
      background-color: white;
    }
    > span {
      display: flex;
      padding: $base*1.5 $base*2 $base*1.5 $base*3;
      justify-content: space-between;
      .title {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .header {
      display: flex;
      white-space: nowrap;
      max-width: calc(100% - 50px);
      flex: 1 1 calc(100% - 50px);

      span {
        &:nth-child(2) {
          flex: 0 0 auto;
          margin-right: $base*2;
        }
        &:nth-child(3) {
          flex: 1 1 auto;
          white-space: break-spaces;
        }
      }
    }
    p {
      font-size: 14px;
      color: $dark-gold;
      padding: $base*1.5 $base*7.5 $base*3;
    }
    span[class*="icon-"] {
      font-size: 16px;
      margin-right: $base*2;
    }
  }
  .MuiMenu-paper {
    box-shadow: none;
  }
  @media #{$mobile} {

      li {
        .header {
          flex-wrap: wrap;
          span:last-child {
            flex: 1 1 100%;
            padding-left: $base*4
          }
        }
      }

  }
}