@import '../../../../assets/scss/variables';


.asigned-pmas {
  .actions {
    gap: 20px;
    .btn {
      display: inline-flex;
      width: auto;
    }
  }
}