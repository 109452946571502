@import '../../../../assets/scss/variables';


.modal-delete-error {
  .MuiBox-root {
    padding: 60px;

    p {
      margin-bottom: 40px;
    }
  }

  .actions {
    max-width: 560px;
    margin: 0 auto;
    justify-content: center;
    .btn {
      max-width: 260px;
    }
  }
}
