@import '../../../assets/scss/variables';

.App {
  main {
    .welcome {
      .inner {
        max-width: $base*142;
        .form-control {
          a {
            color: $text;
          }
        }
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          display: flex;
          gap: 60px;
          flex-wrap: wrap;
          margin-bottom: $base*6;
          li {
            min-width: 280px;
            flex: 0 0 calc(33% - 40px);
            margin: 0;
            padding: 0;
            span {
              display: block;
              font-size: 30px;
              margin-bottom: $base*2;
            }
          }
        }
        .actions {
          width: 100%;
          margin: 0 auto;
          text-align: left;
          button {
            display: inline-block;
            width: auto;
          }
        }
      }
    }
  }
}