@import '../../assets/scss/variables';

.input {
  &__error {
    color: $error;
    font-size: small;
    line-height: 110%;
  }
  &_label {
    display: block;
    font-size: 12px;
    margin-bottom: $base;
    &__error {
      color: $error;
    }
  }
}