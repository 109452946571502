@import '../../../assets/scss/variables';

.App {
  .login {
    background-image: url("../../../assets/img/bg-login.jpg");
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    .enviroment-tag {
      background: white;
      color: black;
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
    }

    .inner {
      background: #FFFFFF;
      border-radius: 6px;
      padding: $base*7 $base*5.5;
      width: 400px;
      box-sizing: border-box;
      margin: 0 $base*2;
      .logo {
        width: $base*22;
        display: block;
        margin: 0 auto $base*9.5;
      }
      .btn {
        margin-bottom: $base*2;
      }
      .alert,
      .error-msg {
        color: $error;
      }
      .hidden {
        display: none;
      }
    }
    p {
      text-align: center;
    }
  }
}
