@import '../../../../assets/scss/variables';


  .error-label {
    color: red;
  }

  .error-checkbox {
    outline: 1px solid red;
  }

  .error-message {
    color: red;
    font-size: 14px;
  }


