.txt-area {
  font-family: 'Avenir Next', sans-serif;
  border-radius: $base*0.5;
  font-size: 14px;
  padding: $base*2 $base*1.5;
  border: 1px solid $dark-gold;
  box-sizing: border-box;
  width: 100%;
  display: block;
  resize: none;
  height: $base*15;
}

.form-control {
  &.error {
    textarea {
      border: 1px solid red;
    }
    span {
      font-size: 11px;
      color: red;
    }
  }
}